/* CSS from create-react-app */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS Start */

.album-scrolling-background {
  width: 100%;
  height: 500px;
  /* background-image: url('assets/album-wall.png'); */
  background-image: url('assets/album-wall.webp');
  background-size: cover;
  animation: scroll 10s linear infinite;
}

.festival-showcase{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-lineup-background {
  width: 80vmin;
  height: 80vmin;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
  /* background-image: url('assets/festival-poster.png'); */
  background-image: url('assets/festival-poster.webp');
  background-size: cover;
}

@keyframes scroll {
  0% {
      background-position: 0 0;
  }
  100% {
      background-position: -1000px 0;
  }
}

.green-blue-gradient {
  background-image: linear-gradient(to bottom, #28A746, #64B5F6);
}

.blue-green-gradient {
  background-image: linear-gradient(to bottom, #64B5F6, #4CAF50);
}

.green-yellow-gradient {
  background-image: linear-gradient(to bottom, #4CAF50, #FFA726);
}

.green-yellow-blue-gradient{
  background-image: linear-gradient(to bottom, #4CAF50, #FFA726, #64B5F6);
}

.green-yellow-green-gradient{
  background-image: linear-gradient(to bottom, #4CAF50, #FFA726, #28A746);
}

.yellow-orange-gradient {
  background-image: linear-gradient(to bottom, #FFA726, #F44336);
}

.orange-red-gradient {
  background-image: linear-gradient(to bottom, #F44336, #F8BBD0);
}

.red-blue-gradient {
  background-image: linear-gradient(to bottom, #F8BBD0, #28A746);
}

#avatar img {
  width: 20%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 2vmin;
}

.top-artists-pyramid {
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}

td {
  padding: 1vmin;
}

.top-row {
  font-size: 36px;
}

.second-row {
  font-size: 28px;
}

.third-row {
  font-size: 24px;
}

.fourth-row {
  font-size: 32px;
}

.fifth-row {
  font-size: 22px;
}

.sixth-row {
  font-size: 18px;
}

.seventh-row {
  font-size: 16px;
}

.eight-row {
  font-size: 14px;
}

.ninth-row {
  font-size: 10px;
}

.bottom-row {
  font-size: 8px;
}

#album-wall {
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 500px;
}

#festival-poster {
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 500px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #FAFAFA;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}